import "@hotwired/turbo-rails";
import "@rails/request.js";

import "~/config/cookie";
import "~/config/sentry";
import "~/config/mixpanel";

import "~/assets/javascript/controllers";

import "alpine-turbo-drive-adapter";
import Alpine from "alpinejs";
window.Alpine = Alpine;
Alpine.start();
